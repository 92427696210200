import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import Image from 'gatsby-image'
import { graphql, StaticQuery } from "gatsby"
import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { Helmet } from "react-helmet"
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.scss'


const query = graphql`
query {
    musicBackground: file(relativePath: { regex: "/anni-single/" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      single: file(relativePath: { regex: "/anni-single/" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
}
`

const Music = () => {
    return (
        <StaticQuery
            query={query}
            render={data => (
                <Page data={data}></Page>
            )}
        />
    )
}


export class Page extends React.Component {
    render() {
        function buyLink() {
            if (typeof window !== "undefined") {
                if (window.fbq != null) {
                    window.fbq('track', 'InitiateCheckout', {

                        value: 1.29,
                        currency: 'EUR',
                    });
                }
            }
        }
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Anni Perka - Kleines grosses Glück</title>
                    <description>Kleines großes Glück. Die neue Single von Anni Perka.</description>
                </Helmet>
                <BackgroundImage className="musicBackground" fluid={this.props.data.musicBackground.childImageSharp.fluid}>
                </BackgroundImage>
                <Container fluid className="musicContainer shadow">
                    <Row>
                        <Col md="12" className="p-0">
                            <Image className="img-fluid" alt="kleines goßes glück" fluid={this.props.data.single.childImageSharp.fluid}></Image>
                        </Col>
                        <Col md="12" className="text-center musicTitle p-3">
                            <h2>Kleines großes Glück</h2>
                            <h3>Anni Perka</h3>
                        </Col>
                        <Col md="12" className="p-3">
                            <Container className="pl-5 pr-5">
                                <Row className="buyRow p-2">
                                    <Col className="d-flex">
                                        <FontAwesomeIcon icon={fab.faAmazon} size="4x" />
                                    </Col>
                                    <Col className="d-flex align-items-center justify-content-end">
                                        <a href="https://amzn.to/2xAQX32" target="_blank" onClick={buyLink}>
                                            <button className="btn">jetzt kaufen</button>
                                        </a>
                                    </Col>
                                </Row>
                                <Row className="p-2">
                                    <Col className="d-flex">
                                        <FontAwesomeIcon icon={fab.faApple} size="4x" />
                                    </Col>
                                    <Col className="d-flex align-items-center justify-content-end">
                                        <a href="https://apple.co/3dIwwSq" target="_blank" onClick={buyLink}>
                                            <button className="btn">jetzt kaufen</button>
                                        </a>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Music